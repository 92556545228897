import React from "react";
import TitleImageComponent from "../../components/image/TitleImageComponent";
import browserHistory from "../../helper/browserHistory";
import {observer} from "mobx-react";
import {observable} from "mobx";
import config from "../../config/main.config";
import BlogArticleCardComponent from "../blog/BlogArticleCardComponent";
import DatePicker from "react-datepicker";

import moment from "moment";
//import EditHTMLComponent from "../../components/moap-components/EditHTMLComponent";
import Grid from "semantic-ui-react/dist/commonjs/collections/Grid";
import Segment from "semantic-ui-react/dist/commonjs/elements/Segment";
import Button from "semantic-ui-react/dist/commonjs/elements/Button";
import Form from "semantic-ui-react/dist/commonjs/collections/Form";
import Breadcrumb from "semantic-ui-react/dist/commonjs/collections/Breadcrumb";
import Icon from "semantic-ui-react/dist/commonjs/elements/Icon";
import Dropdown from "semantic-ui-react/dist/commonjs/modules/Dropdown";

@observer
class PressPage extends React.Component {

    @observable
    filteredProjects = [];

    @observable
    allProjects = [];

    @observable
    dropDownOpen = false;

    @observable
    timespan = {
        startDate: null,
        endDate: null
    };

    constructor(props) {
        super(props);
            this.state = {
                textStore: {},
                isLoading: true
            }
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        //Get all Projects
        fetch(config.BASE_URL + 'blog/amount-articles/0', {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            }
        }).then((resp) => resp.json())
            .then(data => {
                let articles = data.articles;

                for (let i = 0; i < articles.length; i++) {
                    if (articles[i].categoryId === "dcb83274-dc9e-4ce7-b8e8-e123f2c1d45f") {
                        this.allProjects.push(articles[i]);
                    }
                }

                this.filteredProjects = this.allProjects;
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    filterProjects() {
        //filter projects timespan
        if (this.timespan.startDate !== null && this.timespan.endDate !== null) {
            let startDate = moment(this.timespan.startDate);
            let endDate = moment(this.timespan.endDate);
            let timeFilteredProjects = [];
            for (let i = 0; i < this.allProjects.length; i++) {
                let projectDate = moment(this.allProjects[i].createdAt);
                if (projectDate.startOf('day').isAfter(startDate.startOf('day')) &&
                    projectDate.startOf('day').isBefore(endDate.startOf('day'))) {
                    timeFilteredProjects.push(this.allProjects[i])
                }
            }
            this.filteredProjects = timeFilteredProjects;
        }
    }


    setStartDate(date) {
        if (date === null) {
            console.log("ERROR");
        } else {
            this.timespan.startDate = date;
            this.filterProjects();
        }
    }

    setEndDate(date) {
        if (date === null) {
            console.log("ERROR");
        } else {
            this.timespan.endDate = date;
            this.filterProjects();
        }
    }

    getTimespanToShow() {
        let startDate = this.timespan.startDate;
        let endDate = this.timespan.endDate;

        return startDate.getDate() + "." + startDate.getMonth() + "." + startDate.getFullYear() + " - " +
            endDate.getDate() + "." + endDate.getMonth() + "." + endDate.getFullYear();
    }

    redoTimeSpan() {
        this.timespan.startDate = null;
        this.timespan.endDate = null;
        this.filterProjects();
    }

    render() {
        // const Seperator = "https://www.buergerstiftung-kreis-rv.de/images/seperator.png";


        //this weird construct so on computer there are always two widths padding for the sides
        let showArticles = [];
        for (let i = 0; i < this.filteredProjects.length; i++) {
            if (i % 3 === 0) {
                showArticles.push(<Grid.Column width={2} only={"computer"} key={i + "x"}>
                </Grid.Column>);
                if (i !== 0) {
                    showArticles.push(<Grid.Column width={2} only={"computer"} key={i + "xy"}>
                    </Grid.Column>);
                }
            }
            let article = this.filteredProjects[i];
            showArticles.push(
                <Grid.Column computer={4} tablet={8} mobile={16} stretched key={i}>
                    <BlogArticleCardComponent
                        titleText={article.header}
                        teaserText={article.description}
                        imageLink={article.thumbnail}
                        buttonLink={article.id}
                        dateText={article.createdAt}
                        tags={article.tags}
                    />
                </Grid.Column>
            );
        }

        return (
            <Grid>
                <TitleImageComponent
                    textStore={this.state.textStore}
                    namespace={'press'}
                    imageLinkId={"press-title-image-id"}
                    headerId={"press-title-header-id"}
                    subheaderId={"press-subtitle-header-id"}
                    buttonLinkId={"press-title-button-link-id"}
                    buttonTextId={"press-title-button-text-id"}
                    small
                />
                <Grid.Row>
                    <Grid.Column width={2} computer={'copmuter'}/>
                    <Grid.Column computer={14} tablet={16} mobile={16}>
                        <Breadcrumb className={"breadcrumb-container"}>
                            <Breadcrumb.Section className={"inactive-breadcrumb"} onClick={() => {
                                browserHistory.push("/");
                                this.setState({});
                            }}>
                                HOME</Breadcrumb.Section>
                            <Breadcrumb.Divider icon='right chevron'/>
                            <Breadcrumb.Section className={"active-breadcrumb"}>PRESSE</Breadcrumb.Section>
                        </Breadcrumb>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row centered className={"page-row"}>
                    <Grid.Column computer={5} tablet={8} mobile={16}>
                        <Dropdown
                            text={this.timespan.startDate == null || this.timespan.endDate == null ? "ZEITRAUM" : this.getTimespanToShow()}
                            className={this.timespan.startDate == null || this.timespan.endDate == null ?
                                "inactive-cat-button timespan-dropdown"
                                :
                                "call-to-action-button-blue timespan-dropdown"}
                            simple>
                            <Dropdown.Menu>
                                <Segment>
                                    <Form>
                                        <Form.Group widths={"equal"}>
                                            <Form.Field>
                                                <label className={"text-block"}>START DATUM</label>
                                                <DatePicker
                                                    selected={this.timespan.startDate}
                                                    onChange={date => this.setStartDate(date)}
                                                    selectsStart
                                                    startDate={this.timespan.startDate}
                                                    endDate={this.timespan.endDate}
                                                    dateFormat="dd.MM.yyy"/>
                                            </Form.Field>
                                            <Form.Field>
                                                <label className={"text-block"}>END DATUM</label>
                                                <DatePicker
                                                    selected={this.timespan.endDate}
                                                    onChange={date => this.setEndDate(date)}
                                                    selectsEnd
                                                    endDate={this.timespan.endDate}
                                                    minDate={this.timespan.startDate}
                                                    dateFormat="dd.MM.yyy"/>
                                            </Form.Field>
                                        </Form.Group>
                                        <Form.Field>
                                            <Button icon labelPosition='right'
                                                    inverted color='green'
                                                    onClick={() => this.redoTimeSpan()}>
                                                Zurücksetzen
                                                <Icon name='undo'/>
                                            </Button>
                                        </Form.Field>
                                    </Form>
                                </Segment>
                            </Dropdown.Menu>
                        </Dropdown>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    {showArticles}
                </Grid.Row>
            </Grid>
        );
    }
}

export default PressPage